// extracted by mini-css-extract-plugin
export var area1 = "styles-module--area1--aa941";
export var area2 = "styles-module--area2--8ae10";
export var area3 = "styles-module--area3--70cb5";
export var area4 = "styles-module--area4--245cc";
export var areaListBox = "styles-module--areaListBox--c563b";
export var areaTitle = "styles-module--areaTitle--c13b9";
export var box = "styles-module--box--9450a";
export var coloredLine = "styles-module--coloredLine--8b125";
export var contentBox = "styles-module--contentBox--fbb89";
export var customScrollbar = "styles-module--customScrollbar--9927e";
export var customSwiper = "styles-module--customSwiper--826e9";
export var detail = "styles-module--detail--ce6a4";
export var grayLine = "styles-module--grayLine--05405";
export var hide = "styles-module--hide--89458";
export var image = "styles-module--image--89f3e";
export var imageBox = "styles-module--imageBox--78ab9";
export var myBox = "styles-module--myBox--07c64";
export var officeAddress = "styles-module--officeAddress--1386f";
export var officeCity = "styles-module--officeCity--2cef6";
export var officeCountry = "styles-module--officeCountry--e2b70";
export var show = "styles-module--show--72155";
export var swiperSlideActive = "styles-module--swiperSlideActive--1608c";
export var swiperSlideWithOverlay = "styles-module--swiperSlideWithOverlay--5ebdd";
export var title = "styles-module--title--69d96";
export var workplacePage = "styles-module--workplacePage--76fc8";