import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import * as styles from './styles.module.scss';
import Layout from '../../components/layout';
import ImageBanner from '../../components/ImageBanner';
import SEO from '../../components/seo';

const Office = (props) => {
  const {
    location,
    data,
  } = props;
  const copywriting = data.contentfulCopywriting;
  const { title, image } = data.contentfulCopywriting.workplace.offices || {};
  const officeList = data.contentfulCopywriting.workplace.offices.nodes || [];
  const [currentOffice, setCurrentOffice] = useState([]);
  const [activeOffice, setActiveOffice] = useState('ap');
  // 根据office的Grouped给officeList分组
  const groupedOffices = officeList.reduce((acc, office) => {
    const tag = office?.Grouped;
    if (!acc[tag]) {
      acc[tag] = [];
    }
    acc[tag].push({
      area: office.Area,
      city: office.City,
      country: office.Country,
      address: office.Address,
      tag,
    });
    // console.log('acc', acc);
    return acc;
  }, {});
  const areaMap = {
    na: groupedOffices.na?.[0]?.area,
    me: groupedOffices.me?.[0].area,
    eu: groupedOffices.eu?.[0].area,
    ap: groupedOffices.ap?.[0].area,
  };

  useEffect(() => {
    // console.log('groupedOffices0', groupedOffices);
    if (groupedOffices) {
      setCurrentOffice(groupedOffices.ap);
      setActiveOffice('ap');
    }
  }, [data]);
  const handleOfficeClick = (office) => {
    setCurrentOffice(groupedOffices[office]);
    setActiveOffice(office);
  };

  return (
    <div className={styles.workplacePage}>
      <Layout {...props} hideSearch copywriting={copywriting}>
        <SEO title={data.contentfulCopywriting.workplace.seo.title} keywords={data.contentfulCopywriting.workplace.seo.keywords} description={data.contentfulCopywriting.workplace.seo.description} />
        <ImageBanner content={data.contentfulCopywriting.workplace.banner} />
        <div className={`${styles.box} pb-[100px]`}>
          <div className={styles.myBox}>
            <div className={styles.title} style={{ maxWidth: '1200px' }}>
              {title}
            </div>
            <div className={styles.imageBox}>
              <div>
                <img
                  className={styles.image}
                  src={image}
                  width={1760}
                  height={1000}
                  alt="image"
                />
              </div>
              <span
                onClick={() => handleOfficeClick('na')}
                className={styles.area1}
              // style={{ top: '18%', left: '14%' }}
              >
                <span>{areaMap.na}</span>
                {activeOffice === 'na' && (
                  <div className="flex items-center md:ml-2 ml-[1px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      className="md:h-5 md:w-5"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="10" r="3" />
                      <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </svg>
                  </div>
                )}
              </span>
              <span
                onClick={() => handleOfficeClick('me')}
                className={styles.area2}
              // style={{ top: '31%', left: '45%' }}
              >
                <span>{areaMap.me}</span>
                {activeOffice === 'me' && (
                  <div className="flex items-center md:ml-2 ml-[1px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      className="md:h-5 md:w-5"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="10" r="3" />
                      <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </svg>
                  </div>
                )}
              </span>
              <span
                onClick={() => handleOfficeClick('eu')}
                // className="absolute md:top-[12%] top-[5%] py-[6px] md:py-3 flex items-center font-semibold px-2 md:px-6 border text-[12px] md:text-[16px] lg:text-[18px] xl:text-[20px] cursor-pointer border-[#E4E7EC] bg-white rounded-[200px] md:left-[57%] left-[50%]"
                className={styles.area3}
              // style={{ top: '12%', left: '57%' }}
              >
                <span>{areaMap.eu}</span>
                {activeOffice === 'eu' && (
                  <div className="flex items-center md:ml-2 ml-[1px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      className="md:h-5 md:w-5"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="10" r="3" />
                      <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </svg>
                  </div>
                )}
              </span>
              <span
                onClick={() => handleOfficeClick('ap')}
                // className="absolute md:top-[30%] top-[16%] py-[6px] md:py-3 flex items-center font-semibold px-2 md:px-6 border text-[12px] md:text-[16px] lg:text-[18px] xl:text-[20px] cursor-pointer border-[#E4E7EC] bg-white rounded-[200px] left-[68%]"
                className={styles.area4}
              // style={{ top: '30%', left: '68%' }}
              >
                <span>{areaMap.ap}</span>
                {activeOffice === 'ap' && (
                  <div className="flex items-center md:ml-2 ml-[1px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      className="md:h-5 md:w-5"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="10" r="3" />
                      <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </svg>
                  </div>
                )}
              </span>

              <div
                // className="h-[220px] w-[342px] md:h-[240px] md:w-[349px] lg:h-[320px]  xl:w-[416px] xl:h-[328px]  overflow-hidden  md:bg-white bg-[#FFFFFFBF] border border-[#E4E7EC] absolute md:bottom-1 bottom-[3%] md:left-0 md:py-6 left-1/2 -translate-x-1/2 md:translate-x-0 p-4 pr-[10px]"
                className={styles.areaListBox}
              >
                <div
                  // className={`h-full overflow-auto md:p-3 ${styles.customScrollbar}`}
                  className={`${styles.contentBox} ${styles.customScrollbar}`}
                >
                  <p
                    // className="font-bold md:text-[20px]  text-[16px] leading-[20px]"
                    className={styles.areaTitle}
                  >
                    {currentOffice && currentOffice[0]?.area}
                  </p>
                  {currentOffice?.map((office, index) => (
                    // <div key={index}>{console.log('country', country)}</div>
                    <div key={index}>
                      <p
                        // className="font-bold md:text-[16px] md:leading-[20px] text-[12px] leading-[16px] my-4 md:my-[30px]"
                        className={styles.officeCountry}
                      >
                        {office?.country}
                      </p>
                      {/* {country.map((item, index) => ( */}
                      {/* <div key={index}> */}
                      <p
                        // className="font-semibold md:text-[16px] md:leading-[20px] text-[12px] leading-[16px] mb-3"
                        className={styles.officeCity}
                      >
                        {office?.city}
                      </p>
                      <div
                        // className=" md:text-[16px] md:mb-10 mb-4 md:leading-[20px] text-[12px] leading-[16px] text-[#86868C]"
                        className={styles.officeAddress}
                      >
                        {office?.address}
                      </div>
                      {/* </div> */}
                      {/* ))} */}
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Office;

export const query = graphql`
query campusQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    workplace {
      seo {
        title
        keywords
        description
      }
      banner {
        pc
        mobile
        jump_link
        title
        mobiletitle
        icontext
        desc
      }
      offices {
        title
        image
        nodes {
          Grouped
          Area
          Country
          City
          Address
        }
      }
    }
  }
}
`;
